export const emailUrl = 'https://api.emailjs.com/api/v1.0/email/send';

export const EmailJsConfig = {
  service_id: 'service_8hpq12w',
  template_id: 'template_zhrkxef',
  user_id: '1tpLHihZxPf5x4Y_7',
};

export const TawkConfig = {
  PropertyId: '574dc29243bd28b55c470a46',
  EnglishWidgetName: 'default',
  ChineseWidgetName: '1iddm0g6s',
};

export const Language = {
  English: 'en',
  Chinese: 'zh',
};
