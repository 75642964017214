import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import zh from './locales/zh.json';

const resources = {
    en: {
        translation: en,
    },
    zh: {
        translation: zh,
    },
};

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        // lng: 'en',
        fallbackLng: 'zh',
        detection: DETECTION_OPTIONS,

        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        resources,
    }).then();

export default i18n;
