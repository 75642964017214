import React, { useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Container from '../../common/Container';
import { SvgIcon } from '../../common/SvgIcon';
import { Button } from '../../common/Button';
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span, NavLink, ContextMenu,
} from './styles';

const MenuItem = (props: any) => {
  const { setVisibility } = props;
  const { t } = useTranslation();

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: 'smooth',
    });
    setVisibility(false);
  };
  return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo('about')}>
          <Span>{t('About')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo('clientProject')}>
          <Span>{t('Clients')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo('service')}>
          <Span>{t('Services')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
            style={{ width: '180px' }}
            onClick={() => scrollTo('contact')}
        >
          <Span>
            <Button>{t('Contact')}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
  );
};

const Header = () => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
      <HeaderSection id='header'>
        <Container>
          <Row justify="space-between">
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="logo.png" width="150" height="54" />
            </LogoContainer>
            <NotHidden>
              <MenuItem setVisibility={setVisibility} />
              <NavLink onClick={() => handleChange('en')} style={{ width: '30px' }}>
                <Span>EN</Span>
              </NavLink>
              <NavLink onClick={() => handleChange('zh')} style={{ width: '30px' }}>
                <Span>中</Span>
              </NavLink>
            </NotHidden>
            <ContextMenu>
              <NavLink onClick={() => handleChange('en')} style={{ width: '30px' }}>
                <Span>EN</Span>
              </NavLink>
              <NavLink onClick={() => handleChange('zh')} style={{ width: '30px', marginRight: '20px' }}>
                <Span>中</Span>
              </NavLink>
              <Burger onClick={toggleButton}>
                <Outline />
              </Burger>
            </ContextMenu>
          </Row>
          <Drawer closable={false} open={visible} onClose={toggleButton}>
            <Col style={{ marginBottom: '2.5rem' }}>
              <Label onClick={toggleButton}>
                <Col span={12}>
                  <Menu>Menu</Menu>
                </Col>
                <Col span={12}>
                  <Outline />
                </Col>
              </Label>
            </Col>
            <MenuItem setVisibility={setVisibility} />
          </Drawer>
        </Container>
      </HeaderSection>
  );
};

export default Header;
