import React, { useRef, useState } from 'react';
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import i18n from 'i18next';
import { Language, TawkConfig } from '../common/Constant';

const TawkMessage = () => {
  const [lan, setLan] = useState(i18n.language);
  const tawkRef = useRef<any>();

  i18n.on('languageChanged', (lng) => {
    console.log('language changed to ', lng);
    setLan(lng);
    // setTimeout(() => {
    //   setRefresh(false);
    // }, 1000);
  });

  return (
    <div className="App">
      <TawkMessengerReact
        ref={tawkRef}
        propertyId={TawkConfig.PropertyId}
        widgetId={lan === Language.English ? TawkConfig.EnglishWidgetName : TawkConfig.ChineseWidgetName}
      />
    </div>
  );
};

export default TawkMessage;
